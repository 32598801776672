<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <div
      class="
        container-small
        d-flex
        flex-column
        justify-center
        align-center
        photo-capture-container
      "
    >
      <div class="photo-status">
        <h2
          class="text-center mb-5"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          }"
        >
          Paramétrage
        </h2>
        <div class="d-flex flex-row flex-wrap mt-10 ma-5">
          <div v-if="isCreditEmpty">
            <v-card
              class="text-center px-7"
              :style="{
                borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
                paddingTop: '20px',
              }"
              width="357"
              height="120"
            >
              <div class="row" align="center">
                <div
                  class="col d-flex text-left"
                  :style="{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontWeight: 600,
                  }"
                >
                  <p>Vous êtes revendeur <br />de code ePhoto</p>
                </div>
                <div class="col d-flex align-center">
                  <v-switch
                    inset
                    v-model="reseller"
                    :color="reseller ? WEBAPPPRO_TEXT_COLOR_1 : ''"
                  ></v-switch>
                </div>
              </div>
            </v-card>
          </div>
          <div class="text-left ma-5">
            <p
              class="mb-1"
              :style="{
                fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                fontSize: WEBAPPPRO_FONT_SIZE_3,
                fontWeight: 600,
                paddingLeft: '10px',
              }"
            >
              Recevoir mes emails sur
            </p>
            <v-text-field
              v-model="emailRedirection"
              :placeholder="cleanEmail"
              solo
              class="rounded-bottom ma-2"
              :style="{ borderRadius: '8px', width: '400px' }"
            >
            </v-text-field>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap mt-10 ma-5">
          <MiniCards3Checkbox
            class="ma-5"
            v-for="(item, i) in itemsEmail"
            :key="i"
            :title="item.title"
            :description="item.description"
            :titleX1="item.titleX1"
            :titleX2="item.titleX2"
            checkboxX1Y1Title="Oui"
            checkboxX1Y2Title="Non"
            :checkboxX1Y3Title="
              'Par défaut (' +
              (partnerPlanDefaultConfig.includes(item.typeX1) ? 'Oui)' : 'Non)')
            "
            checkboxX2Y1Title="Oui"
            checkboxX2Y2Title="Non"
            :checkboxX2Y3Title="
              'Par défaut (' +
              (partnerPlanDefaultConfig.includes(item.typeX2) ? 'Oui)' : 'Non)')
            "
            checkboxX1Y1Value="Oui"
            checkboxX1Y2Value="Non"
            checkboxX1Y3Value="Default"
            checkboxX2Y1Value="Oui"
            checkboxX2Y2Value="Non"
            checkboxX2Y3Value="Default"
            :radioValueX1="
              item.valX1 != null ? item.valX1 : findEmailConfig(item.typeX1)
            "
            :radioValueX2="
              item.valX2 != null ? item.valX2 : findEmailConfig(item.typeX2)
            "
            :updateX1="
              rValue => {
                item.valX1 = rValue
              }
            "
            :updateX2="
              rValue => {
                item.valX2 = rValue
              }
            "
          >
          </MiniCards3Checkbox>
        </div>
        <div class="row d-flex justify-center">
          <v-btn
            class="mb-5 mt-7"
            width="240px"
            height="52px"
            @click="updateReseller()"
            :color="WEBAPPPRO_BUTTON_COLOR_1"
            :style="{
              color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
              borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
              textTransform: 'none',
            }"
            >Enregistrer
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from '../../components/Accueil.vue'
import MiniCards3Checkbox from '../../components/MiniCards3Checkbox.vue'
//import { refreshToken } from './RefreshToken.vue'
export default {
  name: 'FicheCandidat',
  components: {
    Accueil,
    MiniCards3Checkbox,
  },
  data: () => ({
    itemsEmail: [
      {
        title: 'Gestion du lien photo',
        description: 'Choisissez à qui envoyer le lien pour réaliser la photo',
        valX1: null,
        valX2: null,
        titleX1: "Envoyer à l'élève :",
        titleX2: "Envoyer à l'auto école :",
        typeX1: 'CLIENT_EMAIL_NEW_QRCODE',
        typeX2: 'PARTNER_EMAIL_NEW_QRCODE',
      },
      {
        title: 'Validation de la commande',
        description:
          'Une fois la photo validée, indiquez qui doit recevoir l’email. Celui-ci ne contient pas le code ePhoto',
        valX1: null,
        valX2: null,
        titleX1: "Envoyer à l'élève :",
        titleX2: "Envoyer à l'auto école :",
        typeX1: 'CLIENT_EMAIL_VERIFICATION_EPHOTO_VALIDATED_WITHOUT_CODE',
        typeX2: 'PARTNER_EMAIL_VERIFICATION_EPHOTO_VALIDATED_WITHOUT_CODE',
      },
      {
        title: 'Réception du code ePhoto',
        description:
          "Une fois le code ePhoto disponible, choisissez qui reçoit l'email avec la plaquette contenant le code",
        valX1: null,
        valX2: null,
        titleX1: "Envoyer à l'élève :",
        titleX2: "Envoyer à l'auto école :",
        typeX1: 'CLIENT_EMAIL_VERIFICATION_EPHOTO_VALIDATED',
        typeX2: 'PARTNER_EMAIL_VERIFICATION_EPHOTO_VALIDATED',
      },
    ],
    partnerPlanDefaultConfig: [],
    informWho: [],
    reseller: false,
    emailRedirection: [],
    email: localStorageService.getValue('partnerEmail'),
    styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
    buttonTextValidColor: localStorageService.getStyle(
      location.hostname,
      'buttonTextValidColor',
    ),
    WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_ICON_COLOR_INFO',
    ),
    WEBAPPPRO_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_1',
    ),
    WEBAPPPRO_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_2',
    ),
    WEBAPPPRO_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_3',
    ),
    WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_1',
    ),
    WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_2',
    ),
    WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_3',
    ),
    WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_COLOR_1',
    ),
    WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
    ),
    icon: localStorageService.getStyle(location.hostname, 'icon'),
    iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_1',
    ),
    WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_2',
    ),
    WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_1',
    ),
    WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_2',
    ),
    WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_SIZE_3',
    ),
    WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_FONT_FAMILY_3',
    ),
    WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_FONT_SIZE',
    ),
    WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_BORDER_ROUND',
    ),
    WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_1',
    ),
    WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BUTTON_SHADOW_2',
    ),
    background_mobile: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_MOBILE',
    ),
    WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_1',
    ),
    WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_2',
    ),
    WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_SHADOW_3',
    ),
    validatedImages: localStorageService.getValue('validatedImages'),
    addOffer: localStorageService.getValue('addOfferId'),
    WEBAPPPRO_LOGO_1: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_1',
    ),
    WEBAPPPRO_LOGO_2: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_LOGO_2',
    ),
    WEBAPPPRO_CARTE: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARTE',
    ),
    WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_CARD_COLOR',
    ),
    WEBAPPPRO_QRCODE_BORDER_COLOR: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_QRCODE_BORDER_COLOR',
    ),
    WEBAPPPRO_QRCODE_SHADOW: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_QRCODE_SHADOW',
    ),
    WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_COLOR_1_FILL_ICON',
    ),
    WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_ETAPES',
    ),
    background_desktop: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_BACKGROUND_DESKTOP',
    ),
    WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
      location.hostname,
      'WEBAPPPRO_TEXT_COLOR_4',
    ),
    goBack: true,
    partnerId: localStorageService.getValue('agenceId'),
    redirect: [],
    credit: localStorageService.getValue('credit'),
  }),
  computed: {
    cleanEmail() {
      return this.email.startsWith('partner_')
        ? this.email.replace('partner_', '')
        : this.email
    },
    isCreditEmpty() {
      return this.credit === 0 || this.credit === null
    },
  },
  methods: {
    findEmailConfig(key) {
      for (const item of this.informWho) {
        // Vérifie si l'élément est un objet et contient la clé
        if (typeof item === 'object' && item !== null && key in item) {
          return parseInt(item[key]) === 1
            ? 'Oui'
            : parseInt(item[key]) === 0
            ? 'Non'
            : 'Default'
        }
      }
      return 'Default'
    },
    getEmailConfig(key) {
      for (const item of this.informWho) {
        // Vérifie si l'élément est un objet et contient la clé
        if (typeof item === 'object' && item !== null && key in item) {
          return parseInt(item[key])
        }
      }
      return undefined
    },
    async updateReseller() {
      //const email = localStorageService.getValue('partnerEmail')
      // Récupérer les titres des éléments sélectionnés
      const informWhoMapped = [
        ...this.itemsEmail.map(item => ({
          key: item.typeX1,
          value:
            item.valX1 === 'Oui'
              ? true
              : item.valX1 === 'Non'
              ? false
              : this.getEmailConfig(item.typeX1),
        })),
        ...this.itemsEmail.map(item => ({
          key: item.typeX2,
          value:
            item.valX2 === 'Oui'
              ? true
              : item.valX2 === 'Non'
              ? false
              : this.getEmailConfig(item.typeX2),
        })),
      ]
      const keys = [
        'CLIENT_EMAIL_NEW_QRCODE',
        'CLIENT_EMAIL_VERIFICATION_REJECTED_PHOTO',
        'CLIENT_EMAIL_VERIFICATION_REJECTED_SIGNATURE',
        'CLIENT_EMAIL_VERIFICATION_EPHOTO_VALIDATED_WITHOUT_CODE',
        'CLIENT_EMAIL_VERIFICATION_EPHOTO_VALIDATED',
        'PARTNER_EMAIL_NEW_QRCODE',
        'PARTNER_EMAIL_VERIFICATION_REJECTED_PHOTO',
        'PARTNER_EMAIL_VERIFICATION_REJECTED_SIGNATURE',
        'PARTNER_EMAIL_VERIFICATION_EPHOTO_VALIDATED',
        'PARTNER_EMAIL_VERIFICATION_EPHOTO_VALIDATED_WITHOUT_CODE',
      ]

      const informWho = keys.map(key => ({
        [key]: informWhoMapped.find(item => item.key === key)?.value,
      }))

      try {
        // Envoyer la valeur à l'API et attendre la réponse
        requestService.post(`/partner/${this.partnerId}`, {
          reseller: this.reseller,
          emailRedirection: Array.isArray(this.emailRedirection)
            ? this.emailRedirection
            : [this.emailRedirection],
          informWho: informWho, // Envoyer uniquement les titres des éléments sélectionnés
        })

        // Confirmation de succès une fois la mise à jour effectuée
        this.$store.dispatch('alert/success', 'Mise à jour effectuée')
      } catch (error) {
        console.error('Erreur lors de la mise à jour du revendeur:', error)
        this.$store.dispatch('alert/error', 'La mise à jour a échoué')
      }
    },
  },
  mounted() {
    requestService
      .get(`/partner/technical-info/${this.$route.params.partner_uid}`, {})
      .then(data => {
        if (data.data) {
          localStorageService.setObject(
            'emailsRedirection',
            data.data.agence.email_redirection,
          )
          localStorageService.setObject('reseller', data.data.agence.reseller)
          localStorageService.setObject(
            'informWho',
            data.data.agence.inform_who,
          )
          localStorageService.setObject(
            'partnerPlan',
            data.data.partnerPlan.inform_who,
          )
          ;(this.partnerPlanDefaultConfig = Array.isArray(
            data.data.partnerPlan.inform_who,
          )
            ? data.data.partnerPlan.inform_who
            : []),
            (this.informWho = Array.isArray(data.data.agence.inform_who)
              ? data.data.agence.inform_who
              : []),
            (this.emailRedirection = Array.isArray(
              data.data.agence.email_redirection,
            )
              ? data.data.agence.email_redirection
              : []),
            (this.reseller = data.data.agence.reseller)
        }
      })
    // Nettoyage des emails pour enlever le préfixe 'partner_' si présent
    this.emailRedirection =
      this.emailRedirection == null ? [] : this.emailRedirection
    this.emailRedirection = this.emailRedirection.map(email =>
      email.startsWith('partner_') ? email.replace('partner_', '') : email,
    )

    // Mettre à jour `emailsRedirectionText` pour l'afficher dans le champ
    this.emailRedirection = this.emailRedirection.join(', ')
  },
}
</script>

<style scoped>
.photo-capture-container {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
}
.photo-status {
  text-align: center;
  margin-bottom: 40px;
  padding-left: 175px;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
.custom-text-field {
  width: 90%;
}
.icon-with-margin {
  margin-right: 20px;
}
</style>
